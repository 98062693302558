import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/Services';

export function usePromptTemplatesQuery(tag?: string) {
  const { promptTemplateService } = useServices();
  return useQuery({
    queryKey: ['prompt-templates', 'tag', tag],
    queryFn: () => {
      if (tag) {
        return promptTemplateService.getTemplatesByTag(tag);
      }

      return promptTemplateService.getTemplates();
    },
  });
}

export function usePurchaserOnboardingPromptTemplatesByTagsQuery(tags?: string[]) {
  const { promptTemplateService } = useServices();
  return useQuery({
    queryKey: ['prompt-templates', 'purchaser-onboarding', tags],
    queryFn: () => {
      if (tags && tags.length > 0) {
        return promptTemplateService.getPurchaserOnboardingTemplatesByTags(tags);
      }

      return promptTemplateService.getPurchaserOnboardingTemplates();
    },
  });
}

export function usePurchaserOnboardingPromptTemplatesQuery() {
  const { promptTemplateService } = useServices();
  return useQuery({
    queryKey: ['prompt-templates', 'purchaser-onboarding'],
    queryFn: () => {
      return promptTemplateService.getPurchaserOnboardingTemplates();
    },
  });
}
